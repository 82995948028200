@import '@nerdwallet/base-styles/styles.scss';
@import '../../../styles/react-media-queries.scss';

$standardHeroMobileContentWidth: 288px; /* 320 - 16 - 16 */
$standardHeroTabletContentWidth: 706px; /* 320 - 16 - 16 */
$standardHeroDesktopContentWidth: 1080px; /* 320 - 16 - 16 */
$standardHeroMobileImageHeight: 228px;
.fontFamilyGotham {
  /* stylelint-disable-next-line property-disallowed-list */
  font-family: #{$font-family-gotham-display};
  @include bold;
}

.heroContainer {
  /* stylelint-disable-next-line property-disallowed-list, color-no-hex */
  background-color: #f4eee6;
}

.inclusiveHeroContainer {
  /* stylelint-disable-next-line property-disallowed-list */
  padding-top: 5rem;
  /* stylelint-disable-next-line property-disallowed-list */
  padding-bottom: 3rem;

  @media #{$mobile-only} {
    /* stylelint-disable-next-line property-disallowed-list */
    padding-top: 3rem;
    /* stylelint-disable-next-line property-disallowed-list */
    padding-bottom: 2rem;
  }
}

.hero {
  @include margin-horizontal-auto;
  @include display-flex;
  @include padding-horizontal-4;
  @include flex-direction-column;
  @include padding-vertical-4;
  @include align-items-center;

  @media #{$min-tablet} {
    @include flex-direction-row;
    width: #{$standardHeroTabletContentWidth};
  }

  @media #{$desktop-only} {
    width: #{$standardHeroDesktopContentWidth};
  }
  /* stylelint-disable-next-line max-nesting-depth , selector-max-class */
  &.reverse-desktop {
    @media #{$min-tablet} {
      @include padding-left-0;
      @include flex-direction-row-reverse;
    }
  }

  @media #{$mobile-only} {
    @include text-align-center;
  }
}

.heroLogoDesktop {
  /* stylelint-disable-next-line unit-disallowed-list */
  max-width: 550px;
  /* stylelint-disable-next-line property-disallowed-list */
  margin-bottom: 2.5rem;

  @media #{$mobile-only} {
    @include display-none;
  }
}

.heroLogoMobile {
  /* stylelint-disable-next-line unit-disallowed-list */
  max-width: 375px;
  /* stylelint-disable-next-line property-disallowed-list */
  margin-bottom: 2.5rem;
  @include padding-horizontal-5;

  @media #{$min-tablet} {
    @include display-none;
  }
}

.heroTextContainer {
  @include display-flex;
  @include flex-direction-column;
  @include justify-content-center;
  @include align-items-center;

  @media #{$desktop-only} {
    @include margin-right-5;
    p {
      /* stylelint-disable-next-line  property-disallowed-list, unit-disallowed-list */
      font-size: 44px;
    }
  }
}

.heroTitle {
  @include margin-bottom-3;
  @media #{$desktop-only} {
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    font-size: 44px;
  }
}

.heroImage {
  width: 26.5rem;
  @include margin-top-3;
  @include margin-top-0--wide;
  @media #{$max-tablet} {
    width: 19rem;
  }

  @media #{$mobile-only} {
    @include width-auto;
    @include margin-horizontal-3;
  }
}

.subHero {
  @include text-align-center--wide;
  @include margin-top-5;
  @include margin-bottom-4;
  @include margin-horizontal-auto;
  /* stylelint-disable-next-line unit-disallowed-list */
  max-width: 810px;

  @media #{$max-tablet} {
    @include margin-horizontal-auto;
    @include margin-horizontal-4;
  }

  @media #{$mobile-only} {
    @include text-align-center;
    @include margin-horizontal-4;
  }
}

.floatImage {
  /* stylelint-disable-next-line unit-disallowed-list */
  max-width: 154px;
  @include margin-horizontal-auto;
  /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
  margin-bottom: -90px;

  @media #{$mobile-only} {
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    margin-bottom: -65px;
  }
}

.parallax {
  /* stylelint-disable-next-line unit-disallowed-list */
  height: 260px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media #{$max-tablet} {
    /* stylelint-disable-next-line unit-disallowed-list */
    height: 260px;
    background-attachment: local;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.parallaxTextContainer {
  @include display-flex;
  @include align-items-center;
  @include justify-content-center;
  @include width-100;
  @include height-100;
  @include color-white;
  @include padding-horizontal-3;
  @include margin-bottom-3;
  text-align: center;
  /* stylelint-disable-next-line property-disallowed-list */
  background-color: rgba(61, 64, 69, 0.6);
}

.parallaxText {
  @include max-width-two-thirds;
  @include max-width-100--wide;
}

.partnersSectionImageContainer {
  @include display-flex;
  @include justify-content-center;
  @include margin-top-4;
  /* stylelint-disable-next-line unit-disallowed-list */
  height: 103px;
}

.partnersSectionImage {
  /* stylelint-disable-next-line unit-disallowed-list */
  width: 367px;
  height: auto;
}

.lookingToLearnSection {
  @include padding-top-5;
  @include padding-bottom-5;
  @include background-blue-lightest;

  /* stylelint-disable-next-line selector-no-qualifying-type, max-nesting-depth */
  a[data-currency='Link'] {
    @include color-green; //!important;
    @include bold;
    @include uppercase;
    /* stylelint-disable-next-line property-disallowed-list, unit-disallowed-list */
    font-size: 14px;
  }

  @media #{$mobile-only} {
    @include text-align-center;
  }
}

.learnMoreSection {
  /* stylelint-disable-next-line property-disallowed-list, color-no-hex */
  background-color: #f4eee6;
  @include display-flex;
  @include justify-content-center;
  @include align-items-center;
  @include padding-vertical-5;
  @include padding-vertical-4--wide;
  @include flex-direction-column;
  @include flex-direction-row--wide;
  @include padding-horizontal-2;
}

.learnMoreSectionImage {
  /* stylelint-disable-next-line unit-disallowed-list */
  max-width: 196px;
}

.learnMoreSectionTextContainer {
  /* stylelint-disable-next-line unit-disallowed-list */
  max-width: 660px;
  @include margin-left-5--wide;
  @include margin-top-3-half;
}

.learnMoreSectionTitle {
  @include margin-bottom-3;
  @include text-align-center;
  @include text-align-left--wide;
}

.learnMoreTextMobile {
  @media #{$mobile-only} {
    @include display-block;
  }

  @include display-none;
}

.learnMoreTextDesktop {
  @media #{$mobile-only} {
    @include display-none;
  }

  @include display-block;
}

.footNoteContainer {
  @include text-align-center;
  @include margin-horizontal-auto;
  @include margin-vertical-5;

  @media #{$mobile-only} {
    @include margin-top-2;
  }
}
