@import '../../../styles/util-styles/aligned.scss';

.heroTopContainer {
  @include width-100;
  @include text-align-center;

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .heroTopCentered {
    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .careerBreadcrumb {
      @include padding-top-4;

      /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
      .careerBreadcrumbInline,
      .careerBreadcrumbCarrot {
        @include display-inline;
      }

      /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
      .careerBreadcrumbCarrot {
        @include color-blue;
      }
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .heroTitle {
      @include color-neutral-darkest;
      @include chronicle-display;
      @include padding-top-4;
      @include padding-bottom-3;
      @include padding-horizontal-4;

      @media #{$breakpoint-wide} {
        width: 68%;
        @include margin-horizontal-auto;
        @include padding-top-5;
        @include padding-bottom-4;
      }
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .heroTitleBreadCrumb {
      @include color-neutral-darkest;
      @include chronicle-display;
      @include padding-top-2;
      @include padding-bottom-3;
      @include padding-horizontal-4;

      @media #{$breakpoint-wide} {
        width: 68%;
        @include margin-horizontal-auto;
        @include padding-top-4;
        @include padding-bottom-4;
      }
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .heroCopy {
      @include color-neutral-darkest;
      @include padding-horizontal-3;
      @include padding-bottom-3;

      @media #{$breakpoint-wide} {
        @include width-two-thirds;
        @include margin-horizontal-auto;
        @include padding-bottom-4;
      }
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .heroButton {
      @include uppercase;

      @include margin-horizontal-auto;

      @include margin-bottom-3;

      @include padding-horizontal-4;

      @media #{$breakpoint-wide} {
        @include margin-bottom-4;
      }
    }
  }
}

.heroBottomImageContainer {
  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .heroImageMobile {
    @include display-block;

    @include display-none--wide;
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .heroImageDesktop {
    @include display-none;

    @include display-block--wide;
  }
}

.heroLinkColorLight a:link {
  /* stylelint-disable-next-line color-no-hex, property-disallowed-list */
  color: #fff;
  /* stylelint-disable-next-line property-disallowed-list */
  text-decoration: none;
}

.heroLinkColorLight a:visited {
  /* stylelint-disable-next-line color-no-hex, property-disallowed-list */
  color: #fff;
  /* stylelint-disable-next-line property-disallowed-list */
  text-decoration: none;
}

.heroLinkColorLight a:hover {
  /* stylelint-disable-next-line color-no-hex, property-disallowed-list */
  color: #fff;
  /* stylelint-disable-next-line property-disallowed-list */
  text-decoration: none;
}

.heroLinkColorLight a:active {
  /* stylelint-disable-next-line color-no-hex, property-disallowed-list */
  color: #fff;
  /* stylelint-disable-next-line property-disallowed-list */
  text-decoration: none;
}
