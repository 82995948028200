@import '../../../styles/util-styles/aligned';

.logoContainer {
  .logoText {
    .pressLogoHeader {
      @include text-align-center;
      @include chronicle-display;
      @include margin-bottom-3;
      @include margin-bottom-3-half--wide;
      @include padding-top-3-half;
      @include padding-top-5--wide;
    }

    .pressLogoCopy {
      @include text-align-center;
      @include width-two-thirds--wide;
      @include margin-horizontal-auto;
    }
  }

  .logoTileContainer {
    /* stylelint-disable-next-line unit-disallowed-list */
    width: 300px;

    @media #{$breakpoint-wide} {
      align-items: normal;
      /* stylelint-disable-next-line unit-disallowed-list */
      width: 708px;
    }

    @media #{$very-wide-layout} {
      /* stylelint-disable-next-line unit-disallowed-list */
      width: 956px;
    }
    &Wide {
      @media #{$very-wide-layout} {
        /* stylelint-disable-next-line unit-disallowed-list */
        width: 770px;
      }
    }
  }

  .logo-nav__tile,
  .logo-nav__tile-awards {
    width: 50%;

    @media #{$breakpoint-wide} {
      width: 20%;
    }

    &--wide {
      /* stylelint-disable-next-line unit-disallowed-list */
      max-height: 210px;
      @media #{$breakpoint-wide} {
        @include width-one-thirds;
      }
    }

    &--mid {
      @media #{$breakpoint-wide} {
        width: 22%;
      }
    }

    &--small {
      @media #{$breakpoint-wide} {
        width: 18%;
      }
    }

    &--center {
      @media #{$mobile-only} {
        @include width-one-thirds;
      }
    }
    &.press-logo-columns-6 {
      img {
        object-fit: contain;
      }

      &.wide {
        @include padding-horizontal-1;
      }

      @media #{$breakpoint-wide} {
        width: 16%;
      }
    }
    &.press-logo-columns-5 {
      @include width-one-thirds;
      @include padding-horizontal-3;

      &.wide {
        @include padding-horizontal-1;
      }

      @media #{$breakpoint-wide} {
        width: 20%;
      }
    }
    &.grow {
      flex-grow: 1;
    }
  }
}

.logo-nav__tile-awards {
  /* stylelint-disable-next-line unit-disallowed-list */
  max-height: 150px;

  @media #{$breakpoint-wide} {
    width: 16%;
    /* stylelint-disable-next-line unit-disallowed-list */
    max-height: 180px;
  }
}
