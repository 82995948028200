@import '../../../styles/util-styles/aligned';

.missionContainer {
  background-color: #f2ede6;
  @include padding-top-4;

  @media #{$breakpoint-wide} {
    @include padding-bottom-4--wide;
  }

  .missionCenteredContainer {
    background-image: url('https://www.nerdwallet.com/cdn/company/2021/splash/Company-Hero-Zoom-BG-Arrow-Mobile-FULL@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: -180px bottom;

    @media #{$breakpoint-wide} {
      background-image: url('https://www.nerdwallet.com/cdn/company/2021/splash/Company-Hero-Header-BG-Desktop@2x_v2.png');
      background-size: contain;
      background-position: left center;
    }

    .missionSplashContainer {
      max-width: 480px;
      @include padding-right-4;
      @include padding-bottom-4;
      @include margin-horizontal-auto;
      @include padding-top-4--wide;
      @include padding-left-5--wide;
      @include padding-bottom-0--wide;
      @include margin-horizontal-0--wide;
      background-image: url('https://www.nerdwallet.com/cdn/company/2021/splash/Company-Hero-Browser-BG-Mobile-v2@2x.png');
      background-size: 95% 93%;
      background-repeat: no-repeat;
      background-position: bottom right;

      @media #{$breakpoint-wide} {
        background-image: url('https://www.nerdwallet.com/cdn/company/2021/splash/lined-paper-corner-yellow-2x.png');
        background-size: 40% 40%;
        background-position: top left;
        max-width: none;
        width: 697px;
      }

      .missionSplashOuter {
        @media #{$breakpoint-wide} {
          @include padding-right-4;
          @include padding-bottom-4;
          background-image: url('https://www.nerdwallet.com/cdn/company/2021/splash/Company-Hero-Browser-BG-Desktop-v2@1x.png');
          background-size: 95% 93%;
          background-repeat: no-repeat;
          background-position: bottom right;
        }

        .missionSplash {
          @include width-100;
          @include color-neutral-darkest;
          @include padding-top-5;
          @include padding-bottom-4;
          @include padding-horizontal-3;
          @include padding-bottom-4--wide;
          @include padding-horizontal-4--wide;
          @include text-align-center;
          background-image: url('https://www.nerdwallet.com/cdn/company/2021/splash/Company-Hero-Browser-Dots-Mobile@2x.png');
          background-repeat: no-repeat;
          background-size: auto 37px;
          background-position: top left;

          @media #{$breakpoint-wide} {
            @include width-100;
          }

          .typeModule {
            @include margin-bottom-2;
            @include text-align-left--wide;
            @include margin-bottom-3--wide;

            @media #{$breakpoint-wide} {
              line-height: 300px;
              white-space: pre-line;
            }
          }

          .typeIntro {
            @include text-align-left--wide;
          }
        }
      }
    }

    .splashImageOuter {
      max-width: 272px;
      background-image: url('https://www.nerdwallet.com/cdn/company/2021/splash/Company-Hero-Zoom-Call-Mobile-BG@2x.png');
      background-size: 94% 90%;
      background-repeat: no-repeat;
      background-position: bottom right;
      @include margin-top-3;
      @include margin-left-auto;
      @include padding-right-3;
      @include padding-bottom-3;

      @media #{$breakpoint-wide} {
        background-image: url('https://www.nerdwallet.com/cdn/company/2021/splash/green-bg.png');
        max-width: 510px;
        margin-right: 7%;
        @include padding-right-4;
        @include padding-bottom-4;
      }

      .splashImageContainer {
        @include margin-top-4;
        @include margin-left-auto;
        @include margin-right-0;
        @include padding-all-0;
        padding-top: 26px;
        background-image: url('https://www.nerdwallet.com/cdn/company/2021/splash/Company-Hero-Browser-Dots-Mobile-Small@2x.png');
        background-repeat: no-repeat;
        background-size: auto 26px;
        background-position: top left;
        @include background-white;

        @media #{$breakpoint-wide} {
          padding-top: 28px;
          background-size: auto 28px;
        }
      }
    }
  }
}

.missionCopyContainer {
  background-color: #f2ede6;
  @include padding-bottom-3;
  @include padding-bottom-5--wide;

  .missionCopy {
    @include text-align-center;
    @include padding-all-3;
    @include width-two-thirds--wide;
    @include margin-horizontal-auto--wide;
    @include padding-horizontal-0--wide;

    .missionCopyTitle {
      @include margin-bottom-3;
      @include margin-bottom-4--wide;
    }
  }
}
